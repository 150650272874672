.text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  color: danger-text-color-dark-background('bg-lvl1') !important;
}

$adapted_text_colors: (
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
  'white',
  'light',
);
@each $color in $adapted_text_colors {
  .bg-#{$color},
  .bg-#{$color} * {
    color: $primary-alt;
  }
}

